@import '../../styles/var.scss';
@import '../../styles/utils.scss';

.music-info {
    // width: 200px; height: 200px;
    // border-radius: 5px;
    cursor: default;
    overflow: hidden;
    background-size: cover;
    @include lightShadow;
    @include set-vw(width, 200px);
    @include set-vw(height, 200px);
    @include set-vw(border-radius, 5px);

    .music-hover {
        width: 100%; height: 100%;
        background: rgba(0, 0, 0, 0.2);
        backdrop-filter: blur(10px);
        color: $WH;
        @include flexColumnCenter;
        justify-content: center;
        transition: 0.2s;
        opacity: 0;

        .music-name {
            line-height: 130%;
            padding: 5px;
            font-weight: 600;
            font-size: 1.5em;
            top: 2.1em;
            text-align: center;
            position: absolute;
            transform: translateY(-50%);
        }

        .play, .pause {
            cursor: pointer;
            margin-top: 2em;

            svg {
                width: 2.1em; height: 2.1em;
            }

            svg path { fill: white; }
        }
    }

    &:hover { .music-hover { opacity: 1; } }
}