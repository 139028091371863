@import "../../styles/var.scss";
@import "../../styles/utils.scss";

.modal-wrapper.login {
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(15px);
  justify-content: center;
  overflow: hidden;
  animation: showModal 0.7s;
  @include flexColumnCenter;
  @include set-vw(width, 800px);
  @include set-vw(height, 500px);
  @include set-vw(border-radius, 30px);

  .login-logo-icon {
    @include set-vw(width, 100px);
    @include set-vw(margin-bottom, 20px);
  }
  .login-logo-text {
    @include set-vw(width, 200px);
  }

  img {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
  }

  span {
    font-weight: 300;
    color: $BK;
    @include set-vw(font-size, 18px);
    @include set-vw(margin-top, 13px);
    @include set-vw(margin-bottom, 35px);
  }

  .login-button {
    box-shadow: 0px 6px 15px rgba(175, 170, 182, 0.15);
    font-weight: 400;
    transition: 0.2s;
    text-align: center;
    position: relative;
    @include hoverDown();
    @include set-vw(margin-bottom, 15px);
    @include set-vw(width, 277px);
    @include set-vw(height, 55px);
    @include set-vw(font-size, 15px);
    @include set-vw(border-radius, 30px);
    @include set-vw(padding-left, 50px);
    @include set-pair-vw(padding, 18px, 30px);

    svg {
      position: absolute;
      @include set-vw(width, 20px);
      @include set-vw(height, 20px);
      @include set-vw(left, 30px);
    }
  }

  .login-privacy-policy {
    color: $BK;
    @include set-vw(font-size, 15px);
    @include set-vw(margin-top, 13px);
    @include set-vw(margin-bottom, 35px);

    a {
      font-weight: 400;
      color: $PU;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
