@import '../../styles//var.scss';
@import '../../styles//utils.scss';

.poetry-recommend.modal-wrapper {
    text-align: center;
    background: $WH;
    display: flex;
    flex-direction: column;
    background: linear-gradient(-45deg, #ffc0ad, #ffa9ca, #aeeaff, #a3ffea);
    background-size: 200% 200%;
    animation: gradient 20s ease infinite;
    @include set-vw(width, 400px);
    @include set-vw(min-height, 500px);
    @include set-vw(padding, 20px);
    @include set-vw(border-radius, 30px);

    .modal-content {
        width: 100%;
        height: 100%;
        @include flexColumnCenter;
        flex-grow: 1;

        .title {
            width: 100%;
            font-weight: 600;
            color: $GR-1;
            text-align: left;
            @include set-vw(font-size, 16px);
            @include set-vw(margin-bottom, 10px);
        }

        .reco-poetry-wrapper {
            flex-grow: 1;
            justify-content: center;
            @include flexColumnCenter;
            @include set-vw(margin-bottom, 20px);

            .reco-poetry {
                height: fit-content;
                white-space: pre-wrap;
                line-height: 150%;
                font-weight: 600;
                color: $BK;
                word-break: keep-all;
                @include set-vw(font-size, 22px);
                @include set-vw(padding, 25px);
            }

            .reco-source {
                font-weight: 600;
                color: $PU-1;
                @include set-vw(font-size, 16px);
                @include set-vw(margin-top, 20px);
            }
        }

        .loader{
            margin: 0 0 2em;
            width: 20%;
            text-align: center;
            padding: 1em;
            margin: 0 auto 1em;
            display: inline-block;
            vertical-align: top;
            @include set-vw(height, 100px);
        }
        
        /*
        Set the color of the icon
        */
        svg path,
        svg rect{
            fill: $PU-1;
        }
    }

    button.copy-reco {
        width: fit-content;
        margin-left: auto; margin-right: auto;
        color: $WH-T;
        position: relative;
        transition: 0.2s;
        text-shadow: 0 2px 4px 4px rgba(112, 112, 112, 0.15);
        @include hoverDown;
        @include set-vw(font-size, 15px);
        @include set-vw(margin-bottom, 10px);

        .check-icon { 
            position: absolute;
            left: 100%;
            top: 0px;
            opacity: 0;
            transition: 0.2s;
            @include set-vw(width, 15px);
            @include set-vw(height, 15px);

            path { fill: $BL; }
        }

        &:focus {
            color: $BL;

            .check-icon { opacity: 1; animation: showBounce 0.3s; }
        }
    }
}