@import '../../styles/var.scss';
@import '../../styles/utils.scss';

.navbar {
    width: 100%;
    height: $navbar-height;
    background: black;
    padding: 0 1vw;
    position: absolute;
    left: 0; top: 0;
    justify-content: space-between;
    @include flexCenter;
    @include set-pair-vw(padding, 0px, 16px);

    button.login-btn {
        margin-left: auto;
        color: $WH;
    }

    .logo {
        height: $navbar-height / 5 * 2;
        width: auto;
        cursor: pointer;
    }

    .user-profile-wrapper {
        position: relative;

        .user-profile-image {
            width: $navbar-height / 2; height: $navbar-height / 2;
            flex-shrink: 0;
            border-radius: 50%;
            object-fit: cover;
            cursor: pointer;
            background: $PU;
            flex-shrink: 0;
        }
        
        .user-info-wrapper {
            position: absolute;
            right: 0; top: calc(100% + 10px);
            @include whiteBlur;
            cursor: default;
            display: none;
            @include set-vw(width, 100px);
            @include set-vw(padding, 15px);
            @include set-vw(border-radius, 10px);
            
            .user-name {
                font-weight: 600;
                color: $BK;
                @include set-vw(font-size, 17px);
            }

            .logout-btn {
                width: fit-content;
                margin-left: auto;
                cursor: pointer;
                color: $GR-1;
                border: 1px solid $GR-1;
                transition: 0.2s;
                @include hoverDown(3px);
                @include set-vw(margin-top, 15px);
                @include set-vw(border-radius, 20px);
                @include set-pair-vw(padding, 5px, 10px);

                &:hover { color: $PU; border-color: $PU; background: $PU-T; }
            }
        }


        &:focus { .user-info-wrapper { display: block; animation: showDown 0.3s; } }
    }
}