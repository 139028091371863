@import '../../styles/var.scss';
@import '../../styles/utils.scss';

.meditation-record-preview {
    width: 100%;
    background: rgba(255, 255, 255, 0.6);
    box-shadow: 0px 5px 20px rgba(175, 170, 182, 0.15);
    overflow: hidden;
    display: flex;
    cursor: pointer;
    transition: 0.2s;
    @include hoverDown;
    @include set-vw(margin-top, 20px);
    @include set-vw(border-radius, 20px);

    .music-info { flex-shrink: 0; }

    .record-info-wrapper {
        flex-grow: 1;
        @include set-pair-vw(margin, 0px, 25px);

        .record-info-title {
            font-weight: 600;
            color: $BK;
            @include set-vw(margin-top, 30px);
            @include set-vw(margin-bottom, 19px);
            @include set-vw(font-size, 20px);
        }

        .record-into-content {
            overflow: hidden;
            font-weight: 300;
            color: $BK;
            @include overFlowText(3);
            @include set-vw(height, 60px);
            @include set-vw(font-size, 15px);
            @include set-vw(line-height, 20px);
            @include set-vw(margin-bottom, 20px);
        }

        .record-tag-wrapper {
            width: 100%; height: 18px;
            display: flex;
            flex-wrap: wrap;
            overflow: hidden;

            .record-tag-elem {
                font-weight: 400;
                color: $GR-2;
                @include set-vw(font-size, 15px);
                @include set-vw(margin-right, 10px);
            }
        }

        .replay-meditation {
            font-weight: 600;
            color: $GR-1;
            border: 1px solid $GR-1;
            float: right;
            transition: 0.2s;
            @include hoverDown;
            @include set-pair-vw(padding, 8px, 15px);
            @include set-vw(font-size, 12px);
            @include set-vw(border-radius, 30px);
            @include set-vw(margin-top, 15px);

            &:hover {
                color: $YE;
                border-color: $YE;
                background: $YE-T;
            }
        }
    }
}