@import './utils.scss';

$YE : #FFB62D;
$YE-T : rgba(255, 226, 173, 0.2);
$PU : #5A1DBD;
$PU-1 : #876CB1;
$PU-T : #5a1dbd2c;
$BK : #595460;
$GR-1 : #AFAAB6;
$GR-2 : #CECDCC;
$GR-3 : #E8E8E8;
$WH : white;
$WH-T : rgba(255, 255, 255, 0.8);
$BL : #2b8fb3;

$BIRD : #dfed4e;
$FIRE : #CC6969;
$WAVE : #6985CC;
$RAIN : #33bdc7;

$navbar-height: 6vh;

@mixin flexCenter {
    display: flex;
    align-items: center;
}

@mixin flexColumnCenter {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@mixin overFlowText($maxLine: 1) {
    overflow: hidden;
    word-wrap: break-word;
    -webkit-line-clamp: $maxLine;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    word-break: keep-all;
}

@mixin shadow { box-shadow: 0px 6px 6px 20px 5px rgba(83, 83, 83, 0.15); }
@mixin lightShadow { box-shadow: 0px 4px 20px 8px rgba(102, 98, 108, 0.15); }
@mixin whiteBlur($background-color:rgba(188, 188, 188, 0.6)) {
    background: $background-color;
    backdrop-filter: blur(15px);
    border: solid white;
    box-shadow: 0 0 15px rgba(255, 255, 255, 0.5) !important;
    @include set-vw(border-width, 2px);
}

@mixin scrollBar($thickness : 3px, $thumb : $BK, $background : transparent) {
    &::-webkit-scrollbar { width: $thickness; background: $background; border-radius: 10px; }
    &::-webkit-scrollbar-thumb { width: $thickness; background: $thumb; border-radius: 10px; }
}

// hover
@mixin hoverDown($px : 5px) {
    &:hover { transform: translateY($px); }
}

// active
@mixin activeScale($scale : 0.95) {
    &:active { transform: scale($scale); }
}