.modal-background {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0; top: 0;
    background: rgba(36, 36, 36, 0.2);
    backdrop-filter: blur(4px);
    display: flex;
    z-index: 100;
    animation: showModalOpacity 0.5s;

    &#center {
        justify-content: center;
        align-items: center;
    }

    &#left {
        justify-content: flex-start;
        align-items: center;
    }

    &#bottom {
        justify-content: center;
        align-items: flex-end;
    }
}