@import '../../styles/var.scss';
@import '../../styles/utils.scss';

.record.modal-wrapper {
    background: $WH-T;
    backdrop-filter: blur(15px);
    cursor: default;
    animation: showModalUp 1s;
    @include shadow;
    @include set-vw(width, 770px);
    // @include set-vw(height, 900px);
    height: 95vh;
    @include set-pair-vw(padding, 22px, 27px);
    @include set-vw(border-top-left-radius, 30px);
    @include set-vw(border-top-right-radius, 30px);

    .modal-title {
        font-weight: 600;
        color: $PU-1;
        @include set-vw(font-size, 20px);
    }

    #modal-close {
        @include set-vw(width, 35px);
        @include set-vw(height, 35px);
        @include set-vw(top, 20px);
        @include set-vw(right, 26px);
        position: absolute;
        cursor: pointer;
        path { fill: $PU; }
    }

    .action-button {
        position: absolute;
        cursor: pointer;
        transition: 0.2s;
        @include hoverDown;
        @include set-vw(width, 21px);
        @include set-vw(height, 21px);
        @include set-vw(top, 300px);
        
        path { fill: $PU; }

        &#change-to-edit-mode {
            @include set-vw(right, 80px);
        }
        &#delete-record {
            @include set-vw(width, 27px);
            @include set-vw(height, 27px);
            @include set-vw(top, 297px);
            @include set-vw(right, 130px);
            path { fill: $GR-1; }
        }
    }

    .modal-contents {
        overflow: visible;
        display: flex;
        flex-direction: column;
        @include set-vw(width, 693px);
        @include set-vw(margin-top, 57px);
        @include set-pair-vw(padding, 0px, 54px);

        .white-box {
            height: calc(95vh - 270px);
            background: rgba(255, 255, 255, 0.6);
            backdrop-filter: blur(15px);
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            pointer-events: none;
            z-index: -1;
            @include set-vw(width, 693px);
            // @include set-vw(height, 672px);
            @include set-vw(border-top-left-radius, 30px);
            @include set-vw(border-top-right-radius, 30px);

            @include DESKTOP {
                height: calc(95vh - get-vw(270px));
            }
        }

        .record-input-container {
            max-height: 43vh;
            overflow-y: auto;
            display: flex;
            flex-direction: column;
        }

        .record-input-label {
            font-weight: 600;
            color: $BK;
            @include set-vw(font-size, 20px);
            @include set-vw(margin-top, 35px);
        }
        .record-input-label + .record-input-description {
            font-weight: 400;
            color: $GR-1;
            @include set-pair-vw(margin, 7px, 0px);
            @include set-vw(font-size, 15px);
        }

        .music-info {
            @include set-vw(width, 246px);
            @include set-vw(height, 246px);
        }
    }

    .meditation-info-wrapper {
        display: flex;
        align-items: flex-start;
        @include set-vw(margin-bottom, 15px);

        .time-info-wrapper {
            text-align: right;
            font-weight: 600;
            color: $BK;
            @include set-vw(width, 160px);
            @include set-vw(margin-right, 20px);
            @include set-vw(margin-top, 40px);

            .date { 
                @include set-vw(font-size, 30px);
                @include set-vw(margin-bottom, 35px);
            }
            .time { 
                @include set-vw(font-size, 20px);
            }
        }

        .sound-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            @include set-vw(width, 160px);
            @include set-vw(margin-left, 20px);
            @include set-vw(margin-top, 30px);
    
            .sound-elem {
                @include flexCenter;
                @include set-vw(margin-bottom, 10px);
    
                svg {
                    @include set-vw(width, 22px);
                    @include set-vw(height, 22px);
                    @include set-vw(margin-right, 10px);
    
                    path { fill: $BK; }
                }
    
                span { 
                    font-weight: 500;
                    color: $GR-1;
                    @include set-vw(font-size, 15px);
                }
            }
        }
    }

    .record-title-wrapper {
        width: 100%;
        display: relative;

        .record-title-input {
            width: 100%;
            font-weight: 500;
            border-bottom: 1px solid $BK;
            @include set-vw(font-size, 30px);
            @include set-vw(margin-top, 10px);
            @include set-vw(padding, 10px);
    
            &::placeholder { color: $GR-2; }
        }

        .quotes { display: none; }
    }

    .content-wrapper {
        width: 100%;
        background: $GR-3;
        @include set-vw(height, 120px);
        @include set-vw(border-radius, 20px);
        @include set-vw(padding, 15px);
        
        textarea {
            width: 100%; height: 100%;
            color: $BK;
            line-height: 136%;
            background: transparent;
            @include scrollBar;
            @include set-vw(font-size, 16px);
        }
    }

    .tag-wrapper {
        width: 80%;
        display: flex;
        flex-wrap: wrap;

        .tag-elem {
            height: fit-content;
            @include set-vw(margin-right, 12px);
            @include set-vw(margin-top, 10px);
            @include set-vw(margin-bottom, 14px);

            input[type=checkbox] { display: none; }

            input[type=checkbox] + label {
                border: 1px solid $GR-1;
                font-weight: 400;
                color: $GR-1;
                cursor: pointer;
                transition: 0.2s;
                @include set-pair-vw(padding, 6px, 10px);
                @include set-vw(border-radius, 30px);
                @include set-vw(font-size, 15px);
            }

            input[type=checkbox]:checked + label {
                border-color: $YE;
                color: $YE;
                background: $YE-T;
            }
        }
    }

    .modal-action-button-wrapper {
        justify-content: flex-end;
        @include flexCenter;
        @include set-vw(margin-top, 15px);

        button.modal-action {
            width: fit-content;
            color: $WH;
            background: $PU;
            transition: 0.2s;
            font-weight: 600;
            @include hoverDown;
            @include set-pair-vw(padding, 13px, 35px);
            @include set-vw(border-radius, 30px);
            @include set-vw(font-size, 16px);
            @include set-vw(margin-left, 10px);
    
            &#cancel {
                background: $GR-1;
            }
        }
    }

    &#view-mode {
        .record-input-label {
            display: none;
        }
        .record-input-label + .record-input-description {
            display: none;
        }

        .record-title-wrapper {
            width: fit-content;
            margin-left: auto;
            margin-right: auto;
            position: relative;
            @include set-vw(margin-top, 15px);

            .record-title-input {
                width: fit-content;
                border: 0;
                padding: 0;
                background: transparent;
                text-align: center;
                text-shadow: 0 0 6px $GR-3;
                @include set-vw(margin-top, 30px);
            }

            .quotes {
                display: block;
                position: absolute; 
                @include set-vw(width, 20px);
                @include set-vw(height, 20px);

                path { fill: $GR-2; }

                &:first-of-type { 
                    transform: scaleX(-1); 
                    @include set-vw(left, -10px);
                    @include set-vw(top, 10px);
                }
                &:last-of-type { 
                    @include set-vw(right, -10px);
                    @include set-vw(top, 10px);
                }
            }
        }

        .content-wrapper {
            background: transparent;
            @include set-vw(min-height, 370px);
            @include set-pair-vw(padding, 0px, 35px);
            @include set-vw(margin-top, 30px);

            textarea {
                font-weight: 300;
                line-height: 160%;
                word-break: keep-all;
                text-align: center;
                @include set-vw(font-size, 20px);
            }
        }

        .tag-wrapper {
            width: 100%;
            @include set-vw(margin-top, 50px);

            .tag-elem {
                border: 0;
                margin: 0;

                input[type=checkbox] + label {
                    display: none;
                    background: transparent;
                    color: $PU-1;
                    border: 0;
                    padding: 0;
                    font-weight: 400;
                    @include set-vw(margin-right, 10px);
                }
                input[type=checkbox]:checked + label { display: block; }
            }
        }
    }
}