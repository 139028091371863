@keyframes showLarger {
    100% { transform: scale(1.1); }
}

@keyframes showModal {
    70% { transform: scale(1.03) translateY(10px); }
}

@keyframes showModalUp {
    0% { transform: translateY(100%); }
    70% { transform: translateY(-10px) scaleY(1.03); }
}

@keyframes showModalRight {
    0% { transform: translateX(-100%); }
    70% { transform: translateX(10px) scaleX(1.03); } 
}

@keyframes showModalOpacity {
    0% { opacity: 0; }
}

@keyframes showDown {
    0% { opacity: 0; transform: translateY(-10px); } 
    100% { opacity: 1; }
}

@keyframes showBounce {
    0% { opacity: 0; }
    70% { transform: translateY(-5px); }
    100% { opacity: 1; }
}

@keyframes showSlide {
    0% { max-height: 0; height: 0; overflow: hidden;}
    100% { height: fit-content; }
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}