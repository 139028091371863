@import '../../styles/var.scss';
@import '../../styles/utils.scss';

.audio-handler {
    position: absolute;
    cursor: default;
    @include whiteBlur;
    @include lightShadow;
    @include set-vw(width, 260px);
    @include set-vw(padding, 40px);
    @include set-vw(bottom, 50px);
    @include set-vw(right, 30px);
    @include set-vw(border-radius, 20px);

    .header {
        font-weight: 500;

        .sound-icon {
            @include set-vw(width, 22px);
            @include set-vw(height, 22px);
        }

        svg { transition: 0.2s; }
    }

    .down-arrow {
        float: right;
        cursor: pointer;
        transition: 0.2s;
        @include set-vw(width, 20px);
        @include set-vw(height, 20px);

        & > path { fill: $BK; }
    }

    .sound-setting-container {
        display: flex;
        flex-direction: column;

        .sound-setting-elem {
            flex-grow: 1;
            @include flexCenter;
            @include set-vw(margin-bottom, 15px);

            &:last-of-type { margin-bottom: 0; }
            
            svg {
                flex-shrink: 0;
                @include set-vw(width, 23px);
                @include set-vw(height, 23px);

                path { fill: $WH; transition: 0.2s; }
            }

            input[type=range] {
                width: 100%;
                // height: 4px !important;
                cursor: pointer;
                background: $GR-3;
                @include set-vw(height, 4px);
                @include set-vw(border-radius, 10px);
                @include set-pair-vw(margin, 0px, 10px);

                &::-webkit-slider-thumb {
                    -webkit-appearance: none;
                    appearance: none;
                    border-radius: 50%;
                    background: $BK;
                    cursor: pointer;
                    transition: 0.2s;
                    @include set-vw(width, 10px);
                    @include set-vw(height, 10px);
                }
            }

            .sound-amount { 
                text-align: right;
                flex-shrink: 0; 
                color: $BK;
                font-weight: 600; 
                @include set-vw(width, 20px); 
                @include set-vw(font-size, 16px);
            }

            @mixin setActiveColor($color) {
                svg path { fill: $color; }
                input[type=range]::-webkit-slider-thumb { background: $color; }
            }

            &.active#bird {
                @include setActiveColor($BIRD);
            }
            &.active#fire {
                @include setActiveColor($FIRE);
            }
            &.active#wave {
                @include setActiveColor($WAVE);
            }
            &.active#rain {
                @include setActiveColor($RAIN);
            }
        }
    }

    &#small-view {
        .header .down-arrow { transform: rotateZ(180deg); }
    }
}

