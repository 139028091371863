@import "../../styles/var.scss";
@import "../../styles/utils.scss";

.meditation-end-popup {
  width: 500px;
  height: 300px;
  background: rgba(255, 255, 255, 0.6);
  box-shadow: 0px 5px 20px rgba(175, 170, 182, 0.15);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  @include set-vw(width, 500px);
  @include set-vw(height, 300px);
  @include set-vw(border-radius, 20px);
  @include set-vw(padding, 20px);

  .meditation-end-title-wrapper {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    @include set-vw(height, 200px);

    .title {
      font-weight: 500;
      color: $BK;
      @include set-vw(font-size, 20px);
      @include set-vw(margin-bottom, 40px);
    }

    .subtitle {
      font-weight: 500;
      color: $PU-1;
      @include set-vw(font-size, 35px);
    }
  }

  .meditation-end-button-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    @include set-vw(gap, 10px);

    button {
      @include set-vw(font-size, 18px);

      &.gray {
        font-weight: 600;
        color: $BK;
        border: 1px solid $BK;
        background: $GR-2;
        float: right;
        transition: 0.2s;
        @include hoverDown;
        @include set-pair-vw(padding, 12px, 25px);
        @include set-vw(font-size, 15px);
        @include set-vw(border-radius, 30px);
      }

      &.point {
        font-weight: 600;
        color: $WH;
        background: $PU;
        border: 1px solid $PU-1;
        float: right;
        transition: 0.2s;
        @include hoverDown;
        @include hoverDown;
        @include set-pair-vw(padding, 12px, 25px);
        @include set-vw(font-size, 15px);
        @include set-vw(border-radius, 30px);
      }
    }
  }
}
